.wrapperTextEditor {
  max-width: 893px;
  margin-top: 32px;
  width: 100%;
}

.ck,
ck-editor_main{
  height: 522px;
}
